exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-establishment-js": () => import("./../../../src/templates/establishment.js" /* webpackChunkName: "component---src-templates-establishment-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-medical-school-js": () => import("./../../../src/templates/medicalSchool.js" /* webpackChunkName: "component---src-templates-medical-school-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

